import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/hepatofelin-toksini-header.jpg"
import LuteinComplexTablet from "./../../images/hepatofelin-toksini-header.jpg"
import LuteinComplexDesktop from "./../../images/hepatofelin-toksini-header.jpg"
import LuteinComplexXL from "./../../images/hepatofelin-toksini-header.jpg"

const HepatoToksini = () => (
  <Layout backButton={true} pageInfo={{ pageName: "hepato-toksini" }}>
    <Seo title="Токсините - опасен враг за организма" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ТОКСИНИТЕ - ОПАСЕН ВРАГ ЗА ОРГАНИЗМА
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Токсините - опасен враг за организма"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Токсините - опасен враг за организма
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Много често, без да подозираме това, страдаме от различни болести,
            които са следствие от натрупването на токсини в организма. Слабост,
            липса на работоспособност, лесна уморяемост, сънливост през деня и
            безсъние през нощта, главоболие - това са само част от симптомите,
            алармиращи за увеличените количества вредни вещества в организма.
          </p>
          <p>
            Изчистването на организма от токсините, или т.нар. детоксикация, до
            преди няколко години се възприемаше като преодоляване на алкохолната
            и най-вече на наркотичната зависимост. В съвременния начин на живот
            детоксикацията на организма е изключително важна. Дишаме замърсен
            въздух, пием замърсена вода, ядем храни със синтетични добавки. Това
            води до натрупване на токсини и се отразява негативно на здравето
            ни. Най-потърпевш е черният дроб, който "обезврежда" токсичните
            продукти, образувани при обмяната на веществата, превръщайки ги в
            безвредни за организма.
          </p>
        </Col>
        <Col sm={12}>
          <p>
            Черният дроб притежава повече функции, от който и да е друг орган.
            Той е "химическата лаборатория" на организма, секретира жлъчния сок
            и играе съществена роля в човешкия метаболизъм. Най-честите пороци
            при хората - злоупотребата с алкохол, тютюнопушенето, преяждането,
            както и приемането на лекарства, някои вируси и паразити, могат да
            предизвикат увреждания на черния дроб. В тези случаи специалистите
            препоръчват препаратите, известни като хепатопротектори. Чрез тях се
            постига възстановяване и обновяване на чернодробните клетки.
            Хепатопротекторите имат имунологичен, антиоксидантен и детоксикиращ
            ефект.
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            В момента един от най-предпочитаните и ефективни хепатопротектори на
            пазара е HEPATOFELIN. HEPATOFELIN е произведен от Capsugel - Франция
            за Vita Herb и е най-новият продукт, предназначен за поддържане на
            чернодробното здраве. HEPATOFELIN съдържа богат комплекс от активни
            съставки в оптимални дози за дневен прием, който подпомага дейността
            на чернодробните клетки, съдейства за образуването и отделянето на
            жлъчка, подпомага обмяната на веществата и детоксикацията на
            организма.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>Можете да откриете ХЕПАТОФЕЛИН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/hepatofelin">
              Повече информация за ХЕПАТОФЕЛИН можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default HepatoToksini
